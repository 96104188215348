import {
  mapStructureByCompType,
  mapStructureById,
  replaceVectorArt,
} from '../utils';
import {
  isFieldContentRole,
  PresetContentFieldRole,
} from '@wix/editor-content-injector';

import type { KitDefinition, KitPresetStructures } from '../types';
import type {
  FieldContentRole,
  SerializedCompStructure,
} from '@wix/document-services-types';

export const setSectionsStickers = (
  sectionsStructures: KitPresetStructures[],
  kitStickers: KitDefinition['stickers'],
): void => {
  if (!kitStickers || kitStickers.length === 0) {
    return;
  }
  let stickerIndex = 0;
  sectionsStructures.forEach(({ structure, mobileStructure }) => {
    const mobileStructureById = mapStructureById(mobileStructure);
    const structureByCompTypeEntry = mapStructureByCompType(structure);
    structureByCompTypeEntry['wysiwyg.viewer.components.VectorImage']?.forEach(
      (vectorArtStructure: SerializedCompStructure) => {
        if (
          vectorArtStructure.id &&
          vectorArtStructure.contentRole &&
          isFieldContentRole(vectorArtStructure.contentRole) &&
          (vectorArtStructure.contentRole as FieldContentRole).fieldRole ===
            PresetContentFieldRole.Sticker
        ) {
          const sticker = kitStickers[stickerIndex];
          replaceVectorArt(vectorArtStructure, sticker);
          const mobileVectorArtStructure =
            mobileStructureById[vectorArtStructure.id];
          if (mobileVectorArtStructure) {
            replaceVectorArt(mobileVectorArtStructure, sticker); // only care about layout
          }
          stickerIndex = (stickerIndex + 1) % kitStickers.length;
        }
      },
    );
  });
};
