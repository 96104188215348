export const IMG_NUMBER_PLACEHOLDER = '__IMG_NUMBER__';

export const msidToImagePatternMap: Record<string, string> = {
  '80a2264d-571e-4049-b75f-f29b5d99b813': `https://static.parastorage.com/services/instagram-business/1.827.0/assets/ig-templates-accounts/Editor/Earthy Aesthetics Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '01980ab8-bace-432c-8ef4-78848514b1b6': `https://static.parastorage.com/services/instagram-business/1.827.0/assets/ig-templates-accounts/Editor/Urban Pop Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '0516549c-5e79-40d3-9b07-77db5c17dec9': `https://static.parastorage.com/services/instagram-business/1.827.0/assets/ig-templates-accounts/Editor/Sleek Patterns Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'c76be555-0ded-4ef0-86b1-33e911e275f0': `https://static.parastorage.com/services/instagram-business/1.827.0/assets/ig-templates-accounts/Editor/Everyday Elegance Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '83ad6586-f388-42f0-bd2a-6c3b88ceb8b1': `https://static.parastorage.com/services/instagram-business/1.827.0/assets/ig-templates-accounts/Editor/Nature Lovers Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '61372a08-2e0d-4388-87a4-7e4d1ce70b05': `https://static.parastorage.com/services/instagram-business/1.827.0/assets/ig-templates-accounts/Editor/Pastel Fashion Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'cb5c435e-e708-4af1-ab0f-62cb207bb2ae': `https://static.parastorage.com/services/instagram-business/1.827.0/assets/ig-templates-accounts/Editor/Classic Monochrome Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'd5776ef2-e231-4c76-906d-51100304a16b': `https://static.parastorage.com/services/instagram-business/1.827.0/assets/ig-templates-accounts/Editor/Summer Vacation Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'dca54aff-57ef-4d1e-aa0d-05ef7e54af24': `https://static.parastorage.com/services/instagram-business/1.758.0/assets/ig-templates-accounts/ADI/Supplies Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '1b78b0e0-330b-4db1-b1ee-55470479d2fe': `https://static.parastorage.com/services/instagram-business/1.758.0/assets/ig-templates-accounts/ADI/Education Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '958573d9-13b6-4551-ad45-f8b67c683067': `https://static.parastorage.com/services/instagram-business/1.758.0/assets/ig-templates-accounts/ADI/Dance Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'd6577fe7-bffa-4eba-98d9-774b9a773482': `https://static.parastorage.com/services/instagram-business/1.758.0/assets/ig-templates-accounts/ADI/Community Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '2b75a86f-23db-47b2-a3bb-0bde0401350f': `https://static.parastorage.com/services/instagram-business/1.758.0/assets/ig-templates-accounts/ADI/Digital Illustration Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '00667910-1a5e-4302-a56d-55b4aeb5c746': `https://static.parastorage.com/services/instagram-business/1.760.0/assets/ig-templates-accounts/ADI/Wine Store Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '052d6462-a163-42dc-96f8-cb9449a7d493': `https://static.parastorage.com/services/instagram-business/1.760.0/assets/ig-templates-accounts/ADI/Wellness Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'c18dc3be-6338-4212-854a-18461d9c3895': `https://static.parastorage.com/services/instagram-business/1.720.0/assets/ig-templates-accounts/ADI/Nature Photography Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '95a6259a-a39c-4e86-b190-1dadbf370479': `https://static.parastorage.com/services/instagram-business/1.720.0/assets/ig-templates-accounts/ADI/Jewelry Insta/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'c940031b-df8e-498c-9f80-80c17971740a': `https://static.parastorage.com/services/instagram-business/1.677.0/assets/ig-templates-accounts/ADI/Mommy Insta/${IMG_NUMBER_PLACEHOLDER}.JPG`,
  '20821dc7-d5a0-4a9a-852a-67528706b1e4': `https://static.parastorage.com/services/instagram-cdn/1.805.0/assets/ig-templates-accounts/Editor/Medical Gallery/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '5d87b4f2-cd53-4d30-9937-a09b408d22c9': `https://static.parastorage.com/services/instagram-cdn/1.805.0/assets/ig-templates-accounts/Editor/Fashion Gallery/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '0469ca96-6448-4531-aaae-82b4e46ab7c2': `https://static.parastorage.com/services/instagram-cdn/1.805.0/assets/ig-templates-accounts/Editor/Bookstore Gallery/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'fa91a0e6-858c-4867-bbf0-ff673e2ea273': `https://static.parastorage.com/services/instagram-cdn/1.805.0/assets/ig-templates-accounts/Editor/Party Planner Gallery/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'c9a567aa-9408-48e2-aa6b-c501e84942a5': `https://static.parastorage.com/services/instagram-cdn/1.805.0/assets/ig-templates-accounts/Editor/Gaming Gallery/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '62ced74f-d655-4c45-9777-bc7d0b8a8422': `https://static.parastorage.com/services/instagram-business/1.677.0/assets/ig-templates-accounts/ADI/Mobile Tech Insta/${IMG_NUMBER_PLACEHOLDER}.JPG`,
  '86025040-5cf2-452b-b249-7f950c599ab1': `https://static.parastorage.com/services/instagram-business/1.677.0/assets/ig-templates-accounts/ADI/Makeup Insta/${IMG_NUMBER_PLACEHOLDER}.JPG`,
  'ab0bdf61-4fbc-4781-98f9-c4a48fa1cdd9': `https://static.parastorage.com/services/instagram-business/1.677.0/assets/ig-templates-accounts/ADI/Summer Insta/${IMG_NUMBER_PLACEHOLDER}.JPG`,
  '5e1fc26b-25c4-4b60-8e02-4b7b9a7d2cca': `https://static.parastorage.com/services/instagram-business/1.677.0/assets/ig-templates-accounts/ADI/Yoga Insta/${IMG_NUMBER_PLACEHOLDER}.JPG`,
  '090c0085-db12-41f9-b1e8-c0fa14054c56': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Style & Design blog/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '2fc5f0c2-7cba-42a7-9b5b-7a72fc9dcad2': `https://static.parastorage.com/services/instagram-business/1.677.0/assets/ig-templates-accounts/ADI/Kids Lifestyle Insta/${IMG_NUMBER_PLACEHOLDER}.JPG`,
  'be8bb6b6-5a85-460b-aaf3-c4c86df80bfa': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Camping Equipment Store/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'c5be9e93-d375-41a4-a8cf-5e122117df82': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Fishing Blog & Forum/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'f833ad99-9ab8-44cf-80b0-bab50ee3877b': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Patisserie artisanale/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '228e0580-8d59-4e26-abea-2eddf66e2869': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Dogs Apparel/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'e9ed25e4-4d98-4bbc-a211-aaa07e13b992': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/CBD Soaps and Candles Store/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '1db2bc27-3de0-47c0-8fde-061a69f02a75': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Food Truck/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'd0a488a6-e2fc-4e9c-9d6d-d621048740dd': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Party Accessories Store/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'ae532355-f1fd-499b-8f9d-79330d090a6c': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Arts and Crafts Forum/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '769605c6-c25f-49f5-a135-c9e9628501d7': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Traveller Blog/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'ad6b77ac-7831-457e-88c5-9f72ca749a52': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Nude Makeup/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'efb98e36-2f08-430f-8090-b55010cd4ffb': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Fast-Food Delivery/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '09102bc8-dcac-4456-8a1b-3b3885ef6340': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Farm to Table Venue/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '6b13e9ad-ab9a-44fc-8b07-50a111ab6231': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Functional Studio/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '07f903e9-329e-4447-89d5-e67262a23bfd': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Personal Blog/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  '0e3f5313-dd44-41a9-b69f-c9758012859f': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Healthy Chef/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'c2a62c61-9e38-483d-9c39-06b52cf80f3a': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Club Music DJ/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'ec4f030a-e6b8-419d-b4ef-ccf220dc68c3': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Cielo Apparel/${IMG_NUMBER_PLACEHOLDER}.jpg`,
  'f5ce9d90-79c8-4812-8f4d-715e4f6f296c': `https://static.parastorage.com/services/instagram-cdn/1.691.0/assets/ig-templates-accounts/Editor/Ice%20Cream%20Parlor/${IMG_NUMBER_PLACEHOLDER}.jpg`,
};

export const DEFAULT_INSTAGRAM_IMAGES = [
  'https://static.parastorage.com/services/instagram-business/1.680.0/assets/ig-templates-accounts/New-IG/Main/1.jpg',
  'https://static.parastorage.com/services/instagram-business/1.680.0/assets/ig-templates-accounts/New-IG/Main/2.jpg',
  'https://static.parastorage.com/services/instagram-business/1.680.0/assets/ig-templates-accounts/New-IG/Main/3.jpg',
  'https://static.parastorage.com/services/instagram-business/1.680.0/assets/ig-templates-accounts/New-IG/Main/4.jpg',
];
