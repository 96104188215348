import _ from 'lodash';
import { mapStructureByCompType } from '../utils';
import { FORMS_TYPES_TO_APPLY_KIT } from '../consts';

import type { KitDefinition } from '../types';
import type {
  StyleRef,
  SerializedCompStructure,
} from '@wix/document-services-types';

export const setFormsStyles = (
  presetStructure: SerializedCompStructure,
  kitForms: KitDefinition['forms'],
): void => {
  const structureByCompTypeEntry = mapStructureByCompType(presetStructure);
  FORMS_TYPES_TO_APPLY_KIT.forEach((formCompType) => {
    structureByCompTypeEntry[formCompType]?.forEach((formStructure) => {
      const formStructureByCompTypeEntry =
        mapStructureByCompType(formStructure);
      Object.entries(kitForms).forEach(([formChildType, formChildStyle]) => {
        formStructureByCompTypeEntry[formChildType]?.forEach(
          (formChildStructure) => {
            if (typeof formChildStyle === 'string') {
              formChildStructure.style = formChildStyle;
            } else {
              if (typeof formChildStructure.style === 'object') {
                formChildStructure.style = {
                  ...formChildStyle,
                  id: formChildStructure.style.id,
                } as StyleRef;
              } else {
                formChildStructure.style = _.omit(formChildStyle, 'id');
              }
            }
          },
        );
      });
    });
  });
};
