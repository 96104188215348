import type { SerializedCompStructure } from '@wix/document-services-types';

export const getInstagramPreviewStructureReplacer = (
  y: number,
): SerializedCompStructure => {
  return {
    type: 'Container',
    components: [
      {
        type: 'Container',
        components: [],
        layout: {
          width: 230,
          height: 264,
          x: 0,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.Column',
        parent: 'comp-lovfl52w',
        id: 'comp-irvfl0a2',
        props: {
          type: 'ColumnProperties',
          alignment: 50,
        },
        design: {
          type: 'MediaContainerDesignData',
          background: {
            type: 'BackgroundMedia',
            mediaRef: {
              type: 'Image',
              title: 'Portrait (1).jpg',
              uri: 'a3c153_df0f2c1592834d5288499f33b98b32c1~mv2.jpg',
              description: 'private',
              width: 1500,
              height: 2000,
              alt: 'Portrait (1).jpg',
              artist: {
                id: '',
                name: '',
              },
              hasAnimation: false,
            },
            color: '#FAFAFA',
            colorOpacity: 1,
            alignType: 'center',
            fittingType: 'fill',
            scrollType: 'none',
            colorOverlay: '',
            colorOverlayOpacity: 0,
            showOverlayForMediaType: 'WixVideo',
            colorLayers: [
              {
                type: 'SolidColorLayer',
                opacity: 0,
                fill: {
                  type: 'SolidColor',
                  color: '{color_11}',
                },
              },
            ],
          },
          charas: 'design-lovfo7wm',
        },
        style: 'mc1',
      },
      {
        type: 'Container',
        components: [],
        layout: {
          width: 230,
          height: 264,
          x: 250,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.Column',
        parent: 'comp-lovfl52w',
        id: 'comp-irvfl0a3',
        props: {
          type: 'ColumnProperties',
          alignment: 50,
        },
        design: {
          type: 'MediaContainerDesignData',
          background: {
            type: 'BackgroundMedia',
            mediaRef: {
              type: 'Image',
              title: 'Portrait (1).jpg',
              uri: 'a3c153_df0f2c1592834d5288499f33b98b32c1~mv2.jpg',
              description: 'private',
              width: 1500,
              height: 2000,
              alt: 'Portrait (1).jpg',
              artist: {
                id: '',
                name: '',
              },
              hasAnimation: false,
            },
            color: '#FAFAFA',
            colorOpacity: 1,
            alignType: 'center',
            fittingType: 'fill',
            scrollType: 'none',
            colorOverlay: '',
            colorOverlayOpacity: 0,
            showOverlayForMediaType: 'WixVideo',
            colorLayers: [
              {
                type: 'SolidColorLayer',
                opacity: 0,
                fill: {
                  type: 'SolidColor',
                  color: '{color_11}',
                },
              },
            ],
          },
          charas: 'design-lovfoe4p',
        },
        style: 'mc1',
      },
      {
        type: 'Container',
        components: [],
        layout: {
          width: 230,
          height: 264,
          x: 500,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.Column',
        parent: 'comp-lovfl52w',
        id: 'comp-irvfl0a4',
        props: {
          type: 'ColumnProperties',
          alignment: 50,
        },
        design: {
          type: 'MediaContainerDesignData',
          background: {
            type: 'BackgroundMedia',
            mediaRef: {
              type: 'Image',
              title: 'Portrait (1).jpg',
              uri: 'a3c153_df0f2c1592834d5288499f33b98b32c1~mv2.jpg',
              description: 'private',
              width: 1500,
              height: 2000,
              alt: 'Portrait (1).jpg',
              artist: {
                id: '',
                name: '',
              },
              hasAnimation: false,
            },
            color: '#FAFAFA',
            colorOpacity: 1,
            alignType: 'center',
            fittingType: 'fill',
            scrollType: 'none',
            colorOverlay: '',
            colorOverlayOpacity: 0,
            showOverlayForMediaType: 'WixVideo',
            colorLayers: [
              {
                type: 'SolidColorLayer',
                opacity: 0,
                fill: {
                  type: 'SolidColor',
                  color: '{color_11}',
                },
              },
            ],
          },
          charas: 'design-lovfol2p',
        },
        style: 'mc1',
      },
      {
        type: 'Container',
        components: [],
        layout: {
          width: 230,
          height: 264,
          x: 750,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.Column',
        parent: 'comp-lovfl52w',
        id: 'comp-irvfl0a5',
        props: {
          type: 'ColumnProperties',
          alignment: 50,
        },
        design: {
          type: 'MediaContainerDesignData',
          background: {
            type: 'BackgroundMedia',
            mediaRef: {
              type: 'Image',
              title: 'Portrait (1).jpg',
              uri: 'a3c153_df0f2c1592834d5288499f33b98b32c1~mv2.jpg',
              description: 'private',
              width: 1500,
              height: 2000,
              alt: 'Portrait (1).jpg',
              artist: {
                id: '',
                name: '',
              },
              hasAnimation: false,
            },
            color: '#FAFAFA',
            colorOpacity: 1,
            alignType: 'center',
            fittingType: 'fill',
            scrollType: 'none',
            colorOverlay: '',
            colorOverlayOpacity: 0,
            showOverlayForMediaType: 'WixVideo',
            colorLayers: [
              {
                type: 'SolidColorLayer',
                opacity: 0,
                fill: {
                  type: 'SolidColor',
                  color: '{color_11}',
                },
              },
            ],
          },
          charas: 'design-lovfor5n',
        },
        style: 'mc1',
      },
    ],
    layout: {
      width: 980,
      height: 264,
      x: 0,
      y,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
    parent: 'comp-lovfl0a1',
    id: 'comp-irvfl0a1',
    props: {
      type: 'StripColumnsContainerProperties',
      fullWidth: true,
      columnsMargin: 20,
      frameMargin: 0,
      rowMargin: 0,
      siteMargin: 0,
    },
    design: {
      type: 'MediaContainerDesignData',
      background: {
        type: 'BackgroundMedia',
        color: '#FFFFFF',
        colorOpacity: 0,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 1,
      },
    },
    style: 'strc1',
  };
};
