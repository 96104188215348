import _ from 'lodash';
import { getInstagramPreviewStructureReplacer } from './instagramPreviewStructureReplacer';
import { INSTAGRAM_FEED } from '@wix/app-definition-ids';
import { PresetContentCategory } from '@wix/editor-content-provider';
import {
  DEFAULT_INSTAGRAM_IMAGES,
  IMG_NUMBER_PLACEHOLDER,
  msidToImagePatternMap,
} from './consts';

import type { SectionPresetStructure } from '../../types';
import type {
  CompLayout,
  SerializedCompStructure,
} from '@wix/document-services-types';

const getInstagramImagesByMetaSiteId = (
  msid: string,
  count: number,
): string[] => {
  const imagesPatternMap = msidToImagePatternMap[msid];
  if (!imagesPatternMap) {
    return DEFAULT_INSTAGRAM_IMAGES;
  }
  return _.times(count).map((i) =>
    imagesPatternMap.replace(IMG_NUMBER_PLACEHOLDER, `0${i + 1}`),
  );
};

const encodeImage = (imageUrl: string): string => {
  const origin = 'https://static.parastorage.com/';
  const path = imageUrl.replace(origin, '');
  return origin + encodeURIComponent(path);
};

const getInstagramWidgetReplacer = (
  msid: string,
  y: number,
): SerializedCompStructure => {
  const instagramPresetStructureReplacer =
    getInstagramPreviewStructureReplacer(y);
  const columns = instagramPresetStructureReplacer.components!;
  const imageUris = getInstagramImagesByMetaSiteId(msid, columns.length).map(
    encodeImage,
  );
  columns.forEach(({ design }, i) => {
    const mediaRef = design?.background?.mediaRef;
    if (!mediaRef) return;
    mediaRef.uri = imageUris[i];
  });
  return instagramPresetStructureReplacer;
};

const replaceInstagramPreviewWidgetRecursive = (
  compStructure: SerializedCompStructure,
  msid: string,
): void => {
  const { components } = compStructure;
  if (!components) return;
  const instagramWidgetChildIndex = components.findIndex(
    ({ data }) => data?.appDefinitionId === INSTAGRAM_FEED,
  );
  if (instagramWidgetChildIndex >= 0) {
    const instagramWidget = components[instagramWidgetChildIndex]!;
    // @ts-ignore
    const { height, y } = instagramWidget.layout;
    const instagramWidgetPlaceholder = getInstagramWidgetReplacer(msid, y);
    // @ts-ignore
    const placeHolderLayout = instagramWidgetPlaceholder.layout as CompLayout;
    const heightDiff = height - placeHolderLayout.height;
    components.forEach(({ layout }) => {
      if (!layout || layout.y <= placeHolderLayout.y) {
        return;
      }
      layout.y = layout.y - heightDiff;
    });
    // @ts-ignore
    compStructure.layout.height = compStructure.layout.height - heightDiff;
    components[instagramWidgetChildIndex] = getInstagramWidgetReplacer(msid, y);
    return;
  }
  components?.forEach((childComponent) => {
    replaceInstagramPreviewWidgetRecursive(childComponent, msid);
  });
};

const replaceInstagramPreviewWidget = (
  rootStructure: SerializedCompStructure,
  msid: string,
): SerializedCompStructure => {
  const clonedStructure = _.cloneDeep(rootStructure);
  replaceInstagramPreviewWidgetRecursive(clonedStructure, msid);
  return clonedStructure;
};

/**
 * We have presets that doesn't render well in previewer.
 * This specific fix is replacing instagram widget with a strip, and setting the instagram images as columns background images.
 * @param sections - all section presets to render in previewer
 */
export const replaceInstagramPreview = (
  sections: SectionPresetStructure[],
): SectionPresetStructure[] => {
  return sections.map((section) => {
    if (section.contentCategory === PresetContentCategory.Instagram) {
      return {
        ...section,
        structure: replaceInstagramPreviewWidget(
          section.structure,
          section.sourceTemplateId,
        ),
      };
    }
    return section;
  });
};
