import _ from 'lodash';
import { getBlankPageStructure } from './getBlankPageStructure';
import {
  COMP_TYPES,
  PROPS_TO_DELETE_FROM_STRUCTURE,
  RESTRICTED_SECTION_CE_TYPES_TO_ADD,
  SECTION_MIN_HEIGHT,
} from '../siteGeneratorUtilsConsts';
import { getBlankSectionStructure } from './getBlankSectionStructure';
import { fetchPresetStructures } from './fetchDataUtils';
import {
  WIX_NEW_STORES,
  WIX_PORTFOLIO,
  WIX_STORES,
} from '@wix/app-definition-ids';
import {
  EditorType,
  InstallInitiator,
  InstallationOriginType,
} from '@wix/platform-editor-sdk';

import type {
  CompData,
  CompLayout,
  CompRef,
  DocumentServicesObject,
  SerializedCompStructure,
  SerializedPageStructure,
  StyleRef,
  StyleRefOrStyleRefs,
} from '@wix/document-services-types';
import { CeType } from '@wix/adi-core-types';
import {
  mapStructureByCompType,
  type KitDefinition,
  type KitSiteStructure,
} from '@wix/editor-kits';
import type {
  FooterPresetDefinition,
  FullHomepageStructure,
  GeneratedHomepage,
  GeneratedPage,
  HeaderPresetDefinition,
  HeaderPresetStructure,
  LayoutFamilyDefinition,
  PageStructure,
  PresetStructure,
  SectionPresetDefinition,
  SectionPresetStructure,
  SiteTheme,
} from '../types';
import type { PageSectionContent } from '@wix/editor-content-provider';
import { adjustHeader } from './headerAndFooterUtils';
import { LockedPresetIds } from '../types';
import { filterSectionsMatrixByDuplicates } from './styleRulesUtils';

export const getLayoutFromSerialized = (
  compStructure: SerializedCompStructure,
): CompLayout => {
  if (!compStructure.layout) {
    throw new Error(
      'no layout on structure, need to implement getLayoutFromSerialized for such layout.',
    );
  }
  return compStructure.layout;
};

export const chooseRandomIndex = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const createPageStructure = (
  pageUriSEO: string,
  sections: PresetStructure[],
): SerializedPageStructure => {
  const pageStructure = getBlankPageStructure(pageUriSEO);
  pageStructure.components = sections.reduce((acc, { structure }) => {
    const lastSection = acc[acc.length - 1];
    const structureLayout = getLayoutFromSerialized(structure);
    if (lastSection) {
      const lastSectionLayout = getLayoutFromSerialized(lastSection);
      structureLayout.y = lastSectionLayout.y + lastSectionLayout.height;
    } else {
      structureLayout.y = 0;
    }
    return [...acc, structure];
  }, [] as SerializedCompStructure[]);
  const lastSection =
    pageStructure.components[pageStructure.components.length - 1];
  const lastSectionLayout = getLayoutFromSerialized(lastSection);
  pageStructure.layout.height = lastSectionLayout.y + lastSectionLayout.height;

  return pageStructure;
};

export const transformCompStructure = (
  structure: SerializedCompStructure,
  transformation: (comp: SerializedCompStructure) => SerializedCompStructure,
): SerializedCompStructure => {
  const transformedStructure = transformation(_.cloneDeep(structure));
  if (transformedStructure.components) {
    transformedStructure.components = transformedStructure.components.map(
      (component) => transformCompStructure(component, transformation),
    );
  }
  return transformedStructure;
};

export const addContentRoleDataToSection = (
  sectionStructure: SerializedCompStructure,
  ceType: CeType,
): SerializedCompStructure => {
  return {
    ...sectionStructure,
    contentRole: {
      ...sectionStructure.contentRole,
      complexity: 'simple',
      contentRole: ceType,
      type: 'ContainerRole',
    },
  };
};

export const deletePropsFromStructure = (
  compStructure: SerializedCompStructure,
) => {
  for (const prop of PROPS_TO_DELETE_FROM_STRUCTURE) {
    if (compStructure.hasOwnProperty(prop)) {
      delete compStructure[prop];
    }
  }

  compStructure.components?.forEach((comp) => {
    deletePropsFromStructure(comp);
  });
};

const replaceChildComps = (
  ds: DocumentServicesObject,
  ref: CompRef,
  replacement: SerializedCompStructure,
) => {
  const childComps = ds.components.getChildren(ref);
  childComps.forEach((component) => ds.components.remove(component));

  replacement.components?.forEach((component) => {
    ds.components.add(ref, component);
  });
};

const replaceStyle = (
  ds: DocumentServicesObject,
  ref: CompRef,
  style?: string | StyleRefOrStyleRefs,
) => {
  if (!style) return;
  if (typeof style === 'string') {
    if (ds.theme.styles.get(style)) {
      ds.components.style.connectToTheme(ref, style);
    }
  } else {
    ds.components.style.update(ref, style as StyleRef);
  }
};

const replaceLayout = (
  ds: DocumentServicesObject,
  ref: CompRef,
  layout?: Partial<CompLayout>,
) => {
  if (!layout) return;
  ds.components.layout.update(ref, {
    height: layout.height,
    fixedPosition: layout.fixedPosition,
  });
};

const adjustBehaviors = (
  ds: DocumentServicesObject,
  ref: CompRef,
  compStructure: SerializedCompStructure,
) => {
  if (!compStructure.behaviors) return;
  const parsedBehaviorsItems = JSON.parse(compStructure.behaviors.items);
  //TODO: remove ts-ignore when types are fixed (https://wix.slack.com/archives/C9N00LLE6/p1701783454664289)
  ds.components.behaviors.update(
    ref,
    parsedBehaviorsItems[0],
    //@ts-ignore
    parsedBehaviorsItems[0].action,
    //@ts-ignore
    parsedBehaviorsItems[0].viewMode,
  );
};

const replaceHeaderOrFooter = (
  ds: DocumentServicesObject,
  ref: CompRef,
  compStructure: SerializedCompStructure,
) => {
  replaceLayout(ds, ref, compStructure.layout);
  adjustBehaviors(ds, ref, compStructure);
  replaceStyle(ds, ref, compStructure.style);
  fixPresetStructureApplicationIds(ds, compStructure);
  replaceChildComps(ds, ref, compStructure);
};

export const replaceHeader = (
  ds: DocumentServicesObject,
  headerStructure: SerializedCompStructure,
) => {
  if (headerStructure.componentType !== COMP_TYPES.HEADER) {
    throw new Error('replaceHeader should be called only with header');
  }
  replaceHeaderOrFooter(ds, ds.siteSegments.getHeader(), headerStructure);
};

export const replaceFooter = (
  ds: DocumentServicesObject,
  footerStructure?: SerializedCompStructure,
) => {
  if (!footerStructure) {
    return;
  }
  if (footerStructure.componentType !== COMP_TYPES.FOOTER) {
    throw new Error('replaceFooter should be called only with footer');
  }
  replaceHeaderOrFooter(ds, ds.siteSegments.getFooter(), footerStructure);
};

export const setSiteTheme = (
  ds: DocumentServicesObject,
  kitDefinition: KitDefinition,
  layoutFamilyDefinition: LayoutFamilyDefinition,
) => {
  const siteTheme = getSiteTheme(ds, kitDefinition, layoutFamilyDefinition);

  ds.theme.colors.update(siteTheme.colors);
  if (siteTheme.linkedColors) {
    // @ts-expect-error
    ds.site.features.update('themeConfig', {
      colorMapping: siteTheme.linkedColors,
    });
  }
  ds.theme.textThemes.update(siteTheme.textThemes);
  Object.entries(siteTheme.styles).forEach(([styleId, styleValue]) => {
    ds.theme.styles.update(styleId, styleValue);
  });
};

export function isExist<T>(value: T | null): value is T {
  return Boolean(value);
}

export function isHomepageStructure(
  pageStructure: PageStructure | FullHomepageStructure,
): pageStructure is FullHomepageStructure {
  return !!(pageStructure as FullHomepageStructure).header;
}

export function isGeneratedHomepageStructure(
  generatedPage: GeneratedHomepage | GeneratedPage,
): generatedPage is GeneratedHomepage {
  return Boolean(
    (generatedPage as GeneratedHomepage).header ||
      (generatedPage as GeneratedHomepage).footer,
  );
}

export const getKitSiteStructure = ({
  header,
  sections,
  footer,
}: FullHomepageStructure): KitSiteStructure => {
  return {
    header: header && {
      structure: header?.structure,
      mobileStructure: header?.mobileStructure,
    },
    sections: sections.map(({ structure, mobileStructure }) => ({
      structure,
      mobileStructure,
    })),
    footer: footer && {
      structure: footer?.structure,
      mobileStructure: footer?.mobileStructure,
    },
  };
};

export const getSectionsStructures = async (
  sectionPresets: SectionPresetDefinition[],
  sectionContents: PageSectionContent[],
): Promise<SectionPresetStructure[]> => {
  return Promise.all(
    sectionPresets.map(async (preset, index) => {
      const { title, presetJsonUrl, presetMobileJsonUrl, _id } = preset;
      const [sectionStructure, mobileStructure] = await fetchPresetStructures([
        presetJsonUrl,
        presetMobileJsonUrl,
      ]);
      const structure = addContentRoleDataToSection(
        sectionStructure,
        sectionContents[index].ceType,
      );
      return {
        ...sectionContents[index],
        title,
        id: _id,
        structure,
        mobileStructure,
      };
    }),
  ).then((sections) =>
    sections.filter((s): s is SectionPresetStructure => isExist(s)),
  );
};

export const replaceSectionsAnchorNames = (
  sections: SectionPresetStructure[],
  t: (key: string) => string,
): void => {
  sections.forEach(({ structure, ceType }) => {
    const key = `Section_Name_${ceType}`;
    if (structure.anchor) {
      structure.anchor.name = t(key) || key;
    }
  });
};

const fixDataApplicationId = (
  ds: DocumentServicesObject,
  compData: CompData,
): void => {
  if (!compData || !compData?.applicationId || !compData?.appDefinitionId) {
    return;
  }
  const applicationId = ds.platform.getAppDataByAppDefId(
    compData.appDefinitionId,
  )?.applicationId;
  if (!applicationId) {
    return;
  }
  compData.applicationId = `${applicationId}`;
};

export const fixPresetStructureApplicationIds = (
  ds: DocumentServicesObject,
  presetStructure: SerializedCompStructure | SerializedPageStructure,
): void => {
  fixDataApplicationId(ds, presetStructure.data);
  presetStructure.components?.forEach((childStructure) => {
    fixPresetStructureApplicationIds(ds, childStructure);
  });
};

export const isAppInstalled = (
  ds: DocumentServicesObject,
  appDefId: string,
): boolean => {
  return ds.platform.isAppActive(appDefId) || ds.tpa.app.isInstalled(appDefId);
};

export const retrieveAppDefIdsFromStructure = (
  compStructure: SerializedCompStructure,
): string[] => {
  const appDefIds: string[] = [];
  if (compStructure.data?.appDefinitionId) {
    appDefIds.push(compStructure.data.appDefinitionId);
  } else if (compStructure.data?.applicationId) {
    appDefIds.push(compStructure.data.applicationId);
  }
  compStructure.components?.forEach((comp) => {
    appDefIds.push(...retrieveAppDefIdsFromStructure(comp));
  });
  return [...new Set(appDefIds)];
};

export const getHomepagePresetsLocked = (
  headers: HeaderPresetDefinition[],
  sectionsMatrix: SectionPresetDefinition[][],
  footers: FooterPresetDefinition[],
  lockedPresetsIds: LockedPresetIds,
): {
  headerPreset: HeaderPresetDefinition | null;
  sectionsPresets: Array<SectionPresetDefinition | null>;
  footerPreset: FooterPresetDefinition | null;
} => {
  const filteredSectionsMatrix =
    filterSectionsMatrixByDuplicates(sectionsMatrix);
  return {
    headerPreset:
      headers.find((header) => header._id === lockedPresetsIds.header) ||
      _.sample(headers) ||
      null,
    sectionsPresets: filteredSectionsMatrix.map(
      (sectionList) =>
        sectionList.find((section) =>
          lockedPresetsIds.sections.find(
            (presetId) => section._id === presetId,
          ),
        ) || sectionList[0],
    ),
    footerPreset:
      footers.find((footer) => footer._id === lockedPresetsIds.footer) ||
      _.sample(footers) ||
      null,
  };
};

export const navigateToPage = async (
  ds: DocumentServicesObject,
  pageRef: CompRef,
) => {
  await new Promise<void>((resolve) =>
    ds.pages.navigateTo(pageRef.id, resolve),
  );
  await ds.waitForChangesAppliedAsync();
};

export const removePreviousPage = async (
  ds: DocumentServicesObject,
  currentHomepageId: string,
) => {
  ds.pages.remove(currentHomepageId);
  await ds.waitForChangesAppliedAsync();
};

export const adjustAndReplaceHeader = (
  ds: DocumentServicesObject,
  header: HeaderPresetStructure | null,
): void => {
  if (!header) {
    return;
  }
  header.structure = adjustHeader(header.structure, (appDefId: string) =>
    isAppInstalled(ds, appDefId),
  );
  header.mobileStructure = adjustHeader(
    header.mobileStructure,
    (appDefId: string) => isAppInstalled(ds, appDefId),
  );
  replaceHeader(ds, header.structure);
};

export const getSiteTheme = (
  ds: DocumentServicesObject,
  kitDefinition: KitDefinition,
  layoutFamilyDefinition: LayoutFamilyDefinition,
): SiteTheme => {
  return {
    textThemes: _.defaultsDeep(
      {},
      layoutFamilyDefinition.theme,
      kitDefinition.fonts,
      ds.theme.textThemes.getAll(),
    ),
    colors: kitDefinition.colors,
    linkedColors: kitDefinition.linkedColors,
    styles: kitDefinition.themeStyles,
  };
};

export const setUsesNewAnimations = (ds: DocumentServicesObject) => {
  const masterPageRef = ds.components.get.byId(
    ds.pages.getMasterPageId(),
  ) as CompRef;
  ds.components.data.update(masterPageRef, { usesNewAnimations: true });
};

const getCustomAppPageAsHomepage = (
  appDefIds: string[],
  sections: SectionPresetStructure[],
): { appDefId: string; ceType: CeType } | null => {
  if (
    appDefIds.includes(WIX_PORTFOLIO) &&
    sections.find(({ ceType }) => ceType === CeType.Portfolio_Widget)
  ) {
    return {
      appDefId: WIX_PORTFOLIO,
      ceType: CeType.Portfolio_Widget,
    };
  }
  return null;
};

const applyWidgetCustomData = (
  ds: DocumentServicesObject,
  appPageRef: CompRef,
  sourceSection: SectionPresetStructure,
) => {
  const refComps = ds.components.get.byType(
    COMP_TYPES.REF_COMPONENT,
    appPageRef,
  );
  const structureRefComponents = mapStructureByCompType(
    sourceSection.structure,
  )['wysiwyg.viewer.components.RefComponent'];
  if (refComps.length !== 1 || structureRefComponents?.length !== 1) {
    return;
  }
  const [compRef] = refComps;
  const [sourceRefCompStructure] = structureRefComponents;
  if (!sourceRefCompStructure.presets) {
    return;
  }
  ds.appStudioWidgets.presets.change(
    compRef,
    sourceRefCompStructure.presets.style,
    sourceRefCompStructure.presets.layout,
  );

  let mobileVariant = ds.variants.mobile.get();
  if (!mobileVariant) {
    mobileVariant = ds.variants.mobile.create();
  }
  const mobileVariantId = mobileVariant.id;
  if (!mobileVariantId) {
    return;
  }
  const mobileScopedPreset =
    sourceRefCompStructure.scopedPresets?.[mobileVariantId];
  if (mobileScopedPreset) {
    const compVariantRef = ds.components.variants.getPointer(compRef, [
      mobileVariant,
    ]);
    ds.appStudioWidgets.presets.change(
      compVariantRef,
      mobileScopedPreset.style,
      mobileScopedPreset.layout,
    );
  }
};

export const handleCustomAppPageAsHomepage = (
  ds: DocumentServicesObject,
  appDefIds: string[],
  sections: SectionPresetStructure[],
): CompRef | null => {
  const customAppPageAsHomepage = getCustomAppPageAsHomepage(
    appDefIds,
    sections,
  );
  if (!customAppPageAsHomepage) {
    return null;
  }
  const appPageId = ds.pages
    .getPagesData()
    .find(
      ({ managingAppDefId }) =>
        managingAppDefId === customAppPageAsHomepage.appDefId,
    )?.id;
  if (!appPageId) {
    return null;
  }
  const appPageRef = ds.components.get.byId(appPageId) as CompRef;
  const appPageWidgetIndex = sections.findIndex(
    ({ ceType }) => ceType === customAppPageAsHomepage.ceType,
  );
  if (appPageWidgetIndex < 0) {
    return null;
  }
  verifyPageAppWithSections(ds, appPageRef);
  applyWidgetCustomData(ds, appPageRef, sections[appPageWidgetIndex]);
  if (sections.length === 1) {
    return appPageRef;
  }
  const sectionsAbove = sections.slice(0, appPageWidgetIndex);
  const sectionsBelow = sections.slice(appPageWidgetIndex + 1, sections.length);
  unshiftSections(ds, appPageRef, sectionsAbove);
  appendSections(ds, appPageRef, sectionsBelow);
  return appPageRef;
};

export const verifyPageAppWithSections = (
  ds: DocumentServicesObject,
  pageAppRef: CompRef,
) => {
  const pageSections = ds.components.get.byType(COMP_TYPES.SECTION, pageAppRef);
  if (pageSections.length) return;

  const pageHeight = ds.components.layout.get(pageAppRef)?.height;

  const pageComponents = ds.components.getChildren(pageAppRef);

  const pageHeightByComponents = Math.max(
    ...pageComponents
      .map(ds.components.layout.get)
      .map((layout) => (layout?.y || 0) + (layout?.height || 0)),
  );

  const blankSectionStructure = getBlankSectionStructure({
    y: 0,
    height: Math.max(
      pageHeight || 0,
      pageHeightByComponents,
      SECTION_MIN_HEIGHT,
    ),
    name: ds.pages.data.get(pageAppRef.id).title,
  });

  const sectionRef = ds.components.add(pageAppRef, blankSectionStructure);

  pageComponents.forEach((compRef) =>
    ds.components.setContainer(compRef, sectionRef),
  );
};

export const unshiftSections = (
  ds: DocumentServicesObject,
  pageRef: CompRef,
  sections: SectionPresetStructure[],
): void => {
  const totalAddedSectionsHeight = sections.reduce((acc, { structure }) => {
    const layout = getLayoutFromSerialized(structure);
    layout.y = acc;
    return acc + layout.height;
  }, 0);
  const pageSections = ds.components.get.byType(COMP_TYPES.SECTION, pageRef);
  pageSections.forEach((pageSection) => {
    const pageSectionY = ds.components.layout.get(pageSection)!.y;
    ds.components.layout.update(pageSection, {
      y: pageSectionY + totalAddedSectionsHeight,
    });
  });
  sections.forEach(({ structure }, index) => {
    ds.components.add(pageRef, structure, undefined, index);
  });
};

export const appendSections = (
  ds: DocumentServicesObject,
  pageRef: CompRef,
  sections: SectionPresetStructure[],
): void => {
  const pageSections = ds.components.get.byType(COMP_TYPES.SECTION, pageRef);
  const totalAddedSectionsHeight = pageSections.reduce(
    (acc, pageSectionRef) => {
      return ds.components.layout.get(pageSectionRef)!.height + acc;
    },
    0,
  );
  sections.reduce((acc, { structure }) => {
    const layout = getLayoutFromSerialized(structure);
    layout.y = acc;
    return layout.height + acc;
  }, totalAddedSectionsHeight);
  sections.forEach(({ structure }, index) => {
    ds.components.add(
      pageRef,
      structure,
      undefined,
      index + pageSections.length,
    );
  });
};

export const canSectionPresetBeAdded = (
  section: SectionPresetStructure,
): boolean => {
  return !RESTRICTED_SECTION_CE_TYPES_TO_ADD.has(section.ceType);
};

export const syncAndResetMobileLayout = async (
  documentServices: DocumentServicesObject,
) => {
  documentServices.mobileConversion.syncMobileSite();
  await documentServices.waitForChangesAppliedAsync();
  documentServices.mobileConversion.resetMobileLayoutOnAllPages({
    heuristicStrategy: 'default',
  });
  await documentServices.waitForChangesAppliedAsync();
};

export const getUniquePageName = (
  ds: DocumentServicesObject,
  pageName: string,
): string => {
  let sanitizedPageName = pageName
    .replace(/[^A-Za-z0-9\s]/g, '')
    .replace(/\s+/g, '-');
  const siteHasPageWithUriSeo = (pageUriSEO: string) => {
    return !!ds.pages
      .getPagesData()
      .find((pageData) => pageData.pageUriSEO === pageUriSEO);
  };
  let index = 1;
  while (siteHasPageWithUriSeo(sanitizedPageName)) {
    sanitizedPageName += `-${index}`;
    index++;
  }
  return sanitizedPageName;
};

export const getAppDefIdsNotToPersonalize = (
  ds: DocumentServicesObject,
): string[] => {
  return ds.tpa
    .getPendingApps()
    .concat(ds.platform.getInstalledApps())
    .map(({ appDefinitionId }) => appDefinitionId)
    .filter(Boolean) as string[];
};

export const replaceStoresIfNeeded = (appDefId: string): string => {
  if (appDefId === WIX_STORES) {
    return WIX_NEW_STORES;
  }
  return appDefId;
};

export const getSiteGeneratorPlatformOrigin = () => {
  return {
    type: EditorType.Classic,
    initiator: InstallInitiator.Editor,
    info: {
      type: InstallationOriginType.SITE_GENERATION,
    },
  };
};
