import { ADD_PRESET_SERVERLESS_BASE_URL } from '../siteGeneratorUtilsConsts';
import { HttpClient, RequestOptions } from '@wix/http-client';
import { deletePropsFromStructure } from './siteGeneratorUtils';

import type { PresetContentCategory } from '@wix/editor-content-provider';
import type { SerializedCompStructure } from '@wix/document-services-types';
import type {
  LayoutFamilyDefinition,
  SectionPresetDefinition,
  HeaderPresetDefinition,
  FooterPresetDefinition,
} from '../types';

const httpClient = new HttpClient();

const requestData = (url: string, requestOptions: RequestOptions = {}) => {
  return httpClient
    .get(url, requestOptions)
    .then(({ data }) => data)
    .catch((): unknown[] => {
      return [];
    });
};

export const getSectionsPresetsFromServerless = (
  contentCategory: PresetContentCategory,
  layoutFamily: string,
): Promise<SectionPresetDefinition[]> => {
  return requestData(
    `${ADD_PRESET_SERVERLESS_BASE_URL}sections/?contentCategory=${contentCategory}&layoutFamily=${layoutFamily}`,
  );
};

export const getSectionsPresetsFromProduction = (
  contentCategory: string,
  layoutFamily: string,
  presetsBundleBaseUrl: string,
): Promise<SectionPresetDefinition[]> => {
  return requestData(
    `${presetsBundleBaseUrl}/sections_${layoutFamily}_${contentCategory}.json`,
  );
};

export const getSectionsPresets = (
  contentCategory: PresetContentCategory,
  layoutFamily: string,
  isStagingMode: boolean,
  presetsBundleBaseUrl: string,
): Promise<SectionPresetDefinition[]> => {
  return isStagingMode
    ? getSectionsPresetsFromServerless(contentCategory, layoutFamily)
    : getSectionsPresetsFromProduction(
        contentCategory,
        layoutFamily,
        presetsBundleBaseUrl,
      );
};

export const getFootersFromServerless = (
  layoutFamily: string,
): Promise<FooterPresetDefinition[]> => {
  return requestData(
    `${ADD_PRESET_SERVERLESS_BASE_URL}footers/?layoutFamily=${layoutFamily}`,
  );
};

export const getFootersFromProduction = (
  layoutFamily: string,
  presetsBundleBaseUrl: string,
): Promise<FooterPresetDefinition[]> => {
  return requestData(`${presetsBundleBaseUrl}/footers_${layoutFamily}.json`);
};

export const getFooters = async (
  layoutFamily: string,
  isStagingMode: boolean,
  presetsBundleBaseUrl: string,
): Promise<FooterPresetDefinition[]> => {
  return isStagingMode
    ? getFootersFromServerless(layoutFamily)
    : getFootersFromProduction(layoutFamily, presetsBundleBaseUrl);
};

const getHeadersFromServerless = (
  layoutFamily: string,
): Promise<HeaderPresetDefinition[]> => {
  return requestData(
    `${ADD_PRESET_SERVERLESS_BASE_URL}headers/?layoutFamily=${layoutFamily}`,
  );
};

const getHeadersFromProduction = (
  layoutFamily: string,
  presetsBundleBaseUrl: string,
): Promise<HeaderPresetDefinition[]> => {
  return requestData(`${presetsBundleBaseUrl}/headers_${layoutFamily}.json`);
};

export const getHeaders = async (
  layoutFamily: string,
  isStagingMode: boolean,
  presetsBundleBaseUrl: string,
): Promise<HeaderPresetDefinition[]> => {
  return isStagingMode
    ? getHeadersFromServerless(layoutFamily)
    : getHeadersFromProduction(layoutFamily, presetsBundleBaseUrl);
};

export const fetchPresetStructure = (
  presetJsonUrl: string,
): Promise<SerializedCompStructure> => {
  return requestData(presetJsonUrl);
};

export const fetchPresetStructures = async (
  presetJsonUrls: string[],
): Promise<SerializedCompStructure[]> => {
  const structures = await Promise.all(
    presetJsonUrls.map(fetchPresetStructure),
  );
  structures.forEach(deletePropsFromStructure);
  return structures;
};

const fetchLayoutFamiliesFromServerless = (): Promise<
  LayoutFamilyDefinition[]
> => {
  return requestData(`${ADD_PRESET_SERVERLESS_BASE_URL}layoutFamilies`);
};

const fetchLayoutFamiliesFromProduction = (
  presetsBundleBaseUrl: string,
): Promise<LayoutFamilyDefinition[]> => {
  return requestData(`${presetsBundleBaseUrl}/layoutFamilies.json`);
};

export const fetchLayoutFamilies = (
  isStagingMode: boolean,
  presetsBundleBaseUrl: string,
): Promise<LayoutFamilyDefinition[]> => {
  return isStagingMode
    ? fetchLayoutFamiliesFromServerless()
    : fetchLayoutFamiliesFromProduction(presetsBundleBaseUrl);
};
