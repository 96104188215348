import { ErrorReporter } from '@wix/editor-error-reporter';
import {
  LOGGER_ARGS_TO_REPORT,
  LOGGER_PROPS_TO_REPORT,
  STAGING_SUFFIX,
} from '../consts';

import type { FedopsInteraction, SiteGeneratorInteractionName } from '../types';

let fedopsInteractionStarted: FedopsInteraction;
let fedopsInteractionEnded: FedopsInteraction;

export const setFedopsInteractions = ({
  interactionStarted,
  interactionEnded,
}) => {
  fedopsInteractionStarted = interactionStarted;
  fedopsInteractionEnded = interactionEnded;
};

const getArgsNameArray = (target: any): string[] => {
  return target
    .toString()
    .match(/\(.*?\)/)[0]
    .replace(/[()]/gi, '')
    .replace(/\s/gi, '')
    .split(',');
};

function getReportExtras(
  originalThis: any,
  originalMethod: any,
  args: any[],
): Record<string, any> {
  const argsArr = getArgsNameArray(originalMethod);

  const argsObj = argsArr.reduce((acc, arg, index) => {
    acc[arg] = args[index];
    return acc;
  }, {});

  const existingProps = LOGGER_PROPS_TO_REPORT.reduce((acc, prop) => {
    if (originalThis[prop] !== undefined) {
      acc[prop] = originalThis[prop];
    }
    return acc;
  }, {});

  const existingArgs = LOGGER_ARGS_TO_REPORT.reduce((acc, arg) => {
    if (argsObj[arg] !== undefined) {
      acc[arg] = argsObj[arg];
    }
    return acc;
  }, {});

  return { ...existingProps, ...existingArgs };
}

function handleError(
  interactionName: SiteGeneratorInteractionName,
  reportExtras: Record<string, any> = {},
  error: Error,
) {
  ErrorReporter.captureException(error, {
    tags: {
      siteGenerationApiFlow: true,
    },
    extra: {
      operation: interactionName,
      ...reportExtras,
    },
  });
  console.error(error);
}

export const logger = (interactionName: SiteGeneratorInteractionName): any => {
  return function actualDecorator<This, Args extends any[], Return>(
    target: (this: This, ...args: Args) => Return,
  ) {
    return async function replacementMethod(
      this: This,
      ...args: Args
    ): Promise<Return | void> {
      // @ts-ignore
      interactionName = this.useStagingMode
        ? interactionName + STAGING_SUFFIX
        : interactionName;
      const reportExtras = getReportExtras(this, target, args);
      try {
        fedopsInteractionStarted(interactionName);
        let result = target.call(this, ...args);
        const isMethodAsync = result && result instanceof Promise;
        if (isMethodAsync) {
          result = await result;
        }
        fedopsInteractionEnded(interactionName);
        return result;
      } catch (error: any) {
        handleError(interactionName, reportExtras, error);
      }
    };
  };
};

export function verifyInitContent<This, Args extends any[], Return>(
  target: (this: This, ...args: Args) => Return,
  context: ClassMethodDecoratorContext<
    This,
    (this: This, ...args: Args) => Return
  >,
) {
  return function replacementMethod(this: This, ...args: Args): Return | any {
    // @ts-ignore TODO fix types
    if (!this.industryId || !this.structureId) {
      throw new Error('SiteGenerator initContent have not been called');
    }

    return target.call(this, ...args);
  };
}

export function dsDebugTrace<This, Args extends any[], Return>(
  target: (this: This, ...args: Args) => Return,
  context: ClassMethodDecoratorContext<
    This,
    (this: This, ...args: Args) => Return
  >,
) {
  return function replacementMethod(this: This, ...args: Args): Return | any {
    const argsArr = getArgsNameArray(target);
    const dsNameIndex = argsArr.findIndex((argName) => argName === 'ds');
    const ds = dsNameIndex !== -1 ? args[dsNameIndex] : null;
    // @ts-ignore
    if (this.isDebugMode && ds) {
      ds.debug.trace.start();
    }

    const result = target.call(this, ...args);

    const printTrace = () => {
      // @ts-ignore
      if (!this.isDebugMode || !ds) {
        return;
      }
      window.console.log('DS debug trace:');
      window.console.log(ds.debug.trace.getActions());
      ds.debug.trace.stop();
      ds.debug.trace.clean();
    };

    const isMethodAsync = result && result instanceof Promise;
    if (isMethodAsync) {
      result.then((...args) => {
        printTrace();
        return args.length <= 1 ? args[0] : args;
      });
    } else {
      printTrace();
    }
    return result;
  };
}
