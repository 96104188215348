import { createPageStructure } from './siteGeneratorUtils';

import type {
  FooterPresetStructure,
  FullHomepageStructure,
  HeaderPresetStructure,
  SectionPresetStructure,
  PresetStructure,
} from '../types';
import type { SerializedCompStructure } from '@wix/document-services-types';

function isPresetStructure<T extends PresetStructure>(obj: T | null): obj is T {
  return Boolean(obj?.id);
}

const uniteHeaderAndFirstSection = (
  header: HeaderPresetStructure | null,
  firstSection: SectionPresetStructure,
): SectionPresetStructure => {
  if (!header) {
    return firstSection;
  }
  if (header.structure.layout) {
    header.structure.layout.fixedPosition = false;
  }
  // @ts-ignore
  header.structure.transformations = {
    type: 'TransformData',
    zLayer: 'BELOW_PINNED',
  };
  const pageStructure = createPageStructure('headerAndFirstSection', [
    header,
    firstSection,
  ]);
  return {
    ...firstSection,
    structure: pageStructure as SerializedCompStructure,
    sourceTemplateId:
      firstSection.sourceTemplateId || header.sourceTemplateId || '',
  };
};

export const composeHomepagePreviews = ({
  header,
  sections,
  footer,
}: FullHomepageStructure): Array<
  FooterPresetStructure | SectionPresetStructure | HeaderPresetStructure
> => {
  const [firstSection, ...restSections] = sections;
  const headerAndFirstSection = uniteHeaderAndFirstSection(
    header,
    firstSection,
  );
  return [headerAndFirstSection, ...restSections, footer].filter(
    isPresetStructure,
  );
};
